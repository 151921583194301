/* eslint-disable @typescript-eslint/no-unsafe-call */
import rg4js from 'raygun4js';
import packageJson from '../../../../package.json';
rg4js('apiKey', 'MOV73qkG8n0uRjU5UJwyUg');
rg4js('setVersion', packageJson.version);
rg4js('enableCrashReporting', true);
rg4js('enablePulse', true);
rg4js('options', {
  excludedHostnames: ['localhost'],
});
rg4js('withTags', ['fec-ui']); // The env is set later in raygun.service.ts when we have access to the env service.
rg4js('logContentsOfXhrCalls', true);
