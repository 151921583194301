import { Component, OnInit } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { SideBarState, StorageItemKey } from 'src/app/shared/models/app/enum';
import { BrowserSessionStorageService } from '../../services/browser-session-storage.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  public sideBarState$: Observable<SideBarState> = of(SideBarState.NotSet);
  public SideBarState = SideBarState;
  public constructor(public browserSessionStorageService: BrowserSessionStorageService) {}
  public ngOnInit(): void {
    this.sideBarState$ = this.browserSessionStorageService
      .select<boolean>(StorageItemKey.SideBarCollapsed)
      .pipe(
        map((value) => (value === null ? SideBarState.NotSet : value ? SideBarState.Collapsed : SideBarState.Expanded)),
      );
  }
}
