import { Injectable } from '@angular/core';
import { MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  Configuration,
  IPublicClientApplication,
  InteractionType,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';
import { RaygunService } from '../error-handling/raygun.service';
import { BrowserService } from './browser.service';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root',
})
export class AuthConfigService {
  /**
   * Get the Microsoft Authentication Library configuration. For a full list of MSAL.js configuration parameters, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
   */
  public static getMsalConfig(): IPublicClientApplication {
    const envSettings = AuthConfigService.getEnvironmentService().getEnvSettings();

    const isIE =
      window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

    const msalConfig: Configuration = {
      auth: {
        clientId: envSettings.auth.clientId, // This is the ONLY mandatory field that you need to supply.
        authority: `https://${envSettings.auth.authorityDomain}/${envSettings.auth.domainName}/${envSettings.auth.signInPolicy}`, // e.g. 'https://aegispremierdev.b2clogin.com/aegispremierdev.onmicrosoft.com/B2C_1_aegis_signin'
        knownAuthorities: [envSettings.auth.authorityDomain],
        redirectUri: '/msal', // This URL must be registered in the SPA redirect URI section of the Azure B2C app registration (in each env).
        postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge
      },
      system: {
        allowNativeBroker: true,
        loggerOptions: {
          loggerCallback(logLevel: LogLevel, message: string) {
            if (logLevel === LogLevel.Error) {
              AuthConfigService.getRaygunService().logError(message);
            }
          },
          logLevel: LogLevel.Warning, // TODO: Put log level in env file so that prod is set to Error
          piiLoggingEnabled: false,
        },
      },
    };

    return new PublicClientApplication(msalConfig);
  }

  /**
   * Get the guard configuration. Scopes you add here will be prompted for user consent during sign-in.
   * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
   * For more information about OIDC scopes, visit:
   * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
   */
  public static getMsalGuardConfig(): MsalGuardConfiguration {
    return {
      interactionType: InteractionType.Redirect,
      authRequest: {
        scopes: [],
      },
    };
  }

  /**
   * Get the configuration for authenticated APIs. MSAL Angular will automatically retrieve tokens for
   * resources added to protectedResourceMap. For more info, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/initialization.md#get-tokens-for-web-api-calls
   */
  public static getMsalInterceptorConfig(): MsalInterceptorConfiguration {
    const envSettings = AuthConfigService.getEnvironmentService().getEnvSettings();
    const protectedResourceMap = new Map<string, Array<string>>();
    const fecWriteScopes = [`https://${envSettings.auth.domainName}/api/fec.write`];

    protectedResourceMap.set(`${envSettings.fecApiEndpoint}/api/client`, fecWriteScopes);
    protectedResourceMap.set(`${envSettings.fecApiEndpoint}/api/contact`, fecWriteScopes);
    protectedResourceMap.set(`${envSettings.fecApiEndpoint}/api/filerorganization`, fecWriteScopes);
    protectedResourceMap.set(`${envSettings.fecApiEndpoint}/api/formbase`, fecWriteScopes);
    protectedResourceMap.set(`${envSettings.fecApiEndpoint}/api/form1`, fecWriteScopes);
    protectedResourceMap.set(`${envSettings.fecApiEndpoint}/api/form1affiliate`, fecWriteScopes);
    protectedResourceMap.set(`${envSettings.fecApiEndpoint}/api/form1agent`, fecWriteScopes);
    protectedResourceMap.set(`${envSettings.fecApiEndpoint}/api/form1bank`, fecWriteScopes);
    protectedResourceMap.set(`${envSettings.fecApiEndpoint}/api/form99`, fecWriteScopes);
    protectedResourceMap.set(`${envSettings.fecApiEndpoint}/api/queue`, fecWriteScopes);
    protectedResourceMap.set(`${envSettings.fecApiEndpoint}/api/form24`, fecWriteScopes);
    protectedResourceMap.set(`${envSettings.fecApiEndpoint}/api/form3L`, fecWriteScopes);
    protectedResourceMap.set(`${envSettings.fecApiEndpoint}/api/form3lschedulea`, fecWriteScopes);
    protectedResourceMap.set(`${envSettings.fecApiEndpoint}/api/form3LScheduleB`, fecWriteScopes);
    protectedResourceMap.set(`${envSettings.fecApiEndpoint}/api/form3X`, fecWriteScopes);
    protectedResourceMap.set(`${envSettings.fecApiEndpoint}/api/form3xschedulea`, fecWriteScopes);
    protectedResourceMap.set(`${envSettings.fecApiEndpoint}/api/form3xscheduleb`, fecWriteScopes);
    protectedResourceMap.set(`${envSettings.fecApiEndpoint}/api/form3xschedulec`, fecWriteScopes);
    protectedResourceMap.set(`${envSettings.fecApiEndpoint}/api/form3xschedulec1`, fecWriteScopes);
    protectedResourceMap.set(`${envSettings.fecApiEndpoint}/api/form3xschedulec2`, fecWriteScopes);
    protectedResourceMap.set(`${envSettings.fecApiEndpoint}/api/form3xscheduleimport`, fecWriteScopes);
    protectedResourceMap.set(`${envSettings.fecApiEndpoint}/api/scheduleimport`, fecWriteScopes);

    return {
      interactionType: InteractionType.Redirect,
      protectedResourceMap,
    };
  }

  private static getEnvironmentService() {
    const browserService = AuthConfigService.getBrowserService();
    return new EnvironmentService(browserService);
  }

  private static getBrowserService() {
    const platformId = 'browser' as unknown as object;
    return new BrowserService(platformId, window.location, window);
  }

  private static getRaygunService() {
    return new RaygunService(AuthConfigService.getBrowserService(), AuthConfigService.getEnvironmentService());
  }
}
