import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { CurrentUser } from '../models/app/model';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root',
})
export class AppInsightsService {
  public appInsights!: ApplicationInsights;

  public constructor(private environmentService: EnvironmentService) {}

  public initialize() {
    if (this.appInsights) {
      return;
    }

    this.appInsights = new ApplicationInsights({
      config: {
        accountId: 'FEC Filer User',
        appId: 'FEC Filer',
        autoTrackPageVisitTime: true,
        connectionString: this.environmentService.getEnvSettings().appInsightsConnectionString,
        enableAutoRouteTracking: true,
        enableCorsCorrelation: true,
        excludeRequestFromAutoTrackingPatterns: ['api.raygun.io', 'b2clogin.com'],
      },
    });
    this.appInsights.loadAppInsights();
  }

  public setCurrentUser(currentUser: CurrentUser) {
    if (!this.appInsights) {
      this.initialize();
    }
    this.appInsights.setAuthenticatedUserContext(currentUser.userName);
  }

  /**
   * Reset the currently specified user (if any). Typically this will be called during signout.
   */
  public clearUser(): void {
    if (!this.appInsights) {
      this.initialize();
    }
    this.appInsights.clearAuthenticatedUserContext();
  }
}
