import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {
  public service_count = 0; // initialize the counter.

  public constructor(private spinner: NgxSpinnerService) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.service_count++; // increment the count for each intercepted http request.
    if (this.service_count === 1) {
      void this.spinner.show();
    }
    return next.handle(req).pipe(
      finalize(() => {
        this.service_count--; // decrement when service is completed (success/failed both handled when finalize rxjs operator used)
        if (this.service_count === 0) {
          void this.spinner.hide();
        }
      }),
    );
  }
}
