/**
 * Creates an HTTP safe, environment aware URL to an Aegis endpoint.
 */
export class Uri {
  private queryParams = new Map<string, string>();

  /**
   * Initialize a new instance of the Uri class.
   * @param relativeUrl The relative URL to the UBA endpoint, with an optional leading slash.
   * (e.g. '/appInfo' or 'appInfo')
   */
  public constructor(
    private relativeUrl: string,
    private fecApiEndpoint: string,
  ) {
    if (!relativeUrl.startsWith('/')) {
      this.relativeUrl = `/${relativeUrl}`;
    }
  }

  /**
   * Add the specified query name and value to the internal state.
   * @param name The query string name
   * @param value The query string value
   */
  public addQueryParam(name: string, value: string): void {
    this.queryParams.set(name, encodeURIComponent(value));
  }

  /**
   * Generates a fully qualified URL including the domain, relative URL, and query string parameters (if specified).
   * (e.g. https://aegisfec.azurewebsites.net/api/appInfo)
   */
  public toString(): string {
    let url = `${this.fecApiEndpoint}/api${this.relativeUrl}`;

    const kvp = Array.from(this.queryParams, ([key, value]) => {
      return `${key}=${value}`;
    });
    if (kvp.length > 0) {
      url += '?' + kvp.join('&');
    }
    return encodeURI(url);
  }
}
