import { Injectable } from '@angular/core';
import rg4js from 'raygun4js';
import { BrowserService } from '../services/browser.service';
import { EnvironmentService } from '../services/environment.service';

@Injectable({
  providedIn: 'root',
})
export class RaygunService {
  public constructor(
    private browserService: BrowserService,
    private environmentService: EnvironmentService,
  ) {}

  /**
   * Send the specified message or error and optional custom data to Raygun.
   * @param error A string or Error object
   * @param customData An object containing custom data to store in Raygun.
   */
  public logError(error: string | Error | object, customData?: object | object[]): void {
    if (this.isSupportedErrorType(error)) {
      this.sendToRaygun(error, customData);
      return;
    }

    // If we get here, then error is not parsable by Raygun so we need to treat the error as a
    // generic object and log it that way.
    this.logObject(error as object, customData);
  }

  /**
   * Set the tags to be included with error reports to Raygun.
   */
  public setTags() {
    rg4js('withTags', ['fec-ui', this.environmentService.getEnvSettings().envName]);
  }

  /**
   * Tell Raygun about the URL the user just navigated to.
   * @param url The URL (e.g. '/settings')
   */
  public logNavigation(url: string) {
    rg4js('trackEvent', { type: 'pageView', path: url });
  }

  /**
   * Tell Raygun about the currently logged on user so that it is reported along with any logging.
   * @param email The user's email address
   * @param displayName The user's display name
   */
  public setUser(email: string, displayName: string): void {
    rg4js('setUser', {
      identifier: email,
      isAnonymous: false,
      email,
      fullName: displayName,
    });
  }

  /**
   * Reset the currently specified user (if any). Typically this will be called during signout.
   */
  public clearUser(): void {
    rg4js('setUser', {
      identifier: '',
      isAnonymous: true,
      email: '',
      fullName: '',
    });
  }

  /**
   * Send the object to Raygun.
   * @param object The object to log
   * @param customData An object containing custom data to store in Raygun.
   */
  private logObject(object: object, customData: object | object[] | undefined): void {
    // Move error into customData.
    const customDataWithError = this.addErrorToCustomData(object, customData);

    // Cast into object with optional properties so we can safely access them even if they do not exist
    const obj = object as { code?: object; message?: string };

    if (obj?.code && this.isSupportedErrorType(obj.code)) {
      this.sendToRaygun(obj.code, customDataWithError);
    } else if (obj?.message && this.isSupportedErrorType(obj.message)) {
      this.sendToRaygun(obj.message, customDataWithError);
    } else {
      this.sendToRaygun('Unhandled exception', customDataWithError);
    }
  }

  /**
   * Adds the error to customData and return. If customData is an array, then error is inserted as the first item.
   * Otherwise, a new array is created with error and customData as the two items.
   * @param error The error to add to customData. Can be any object.
   * @param customData Any object or array of objects. Can be undefined.
   * @returns A new object that combines error and customData
   */
  private addErrorToCustomData(error: object, customData: object | object[] | undefined) {
    if (!error) {
      return customData;
    }
    if (!customData) {
      return error;
    }
    if (this.isArray(customData)) {
      return [error, ...(customData as [])];
    }
    return [error, customData];
  }

  private enableErrorToJsonMapping(): void {
    if (!('toJSON' in Error.prototype)) {
      Object.defineProperty(Error.prototype, 'toJSON', {
        value(): object {
          const result: { [key: string]: boolean } = {};
          const keys = Object.getOwnPropertyNames(this);
          for (const key of keys) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
            result[key] = this[key];
          }
          return result;
        },
        configurable: true,
        writable: true,
      });
    }
  }

  private isArray(val: object | object[]) {
    return Array.isArray(val);
  }

  /**
   * Returns a value indicating whether the error is a type that Raygun is able to parse.
   * @param error The error to log to Raygun
   * @returns true if Raygun can parse the error; otherwise false
   */
  private isSupportedErrorType(error: string | Error | object) {
    return !!error && (typeof error === 'string' || (error instanceof Error && !!error.message));
  }

  private sendToRaygun(error: string | Error | object, customData: object | object[] | undefined): void {
    this.enableErrorToJsonMapping();
    const errorWithStack = typeof error === 'string' ? new Error(error) : error;
    rg4js('send', { error: errorWithStack, customData });
    const hostname = this.browserService.getLocationHostname();
    if (hostname === 'localhost' || !this.environmentService.getEnvSettings().production) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }
}
