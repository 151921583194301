import { Environment } from 'src/app/shared/models/api/enum';
import { EnvironmentSettings } from 'src/app/shared/models/app/model';

export const environment: EnvironmentSettings = {
  appInsightsConnectionString:
    'InstrumentationKey=0b40de87-135c-4d17-80f2-9830e66848ef;IngestionEndpoint=https://westus2-2.in.applicationinsights.azure.com/;LiveEndpoint=https://westus2.livediagnostics.monitor.azure.com/',
  auth: {
    authorityDomain: 'aegispremiertest.b2clogin.com',
    clientId: 'b463f1d4-b446-4c7e-9dfc-2bca4b4e6fc9', // Maps to Azure AD B2C application (client) ID
    domainName: 'aegispremiertest.onmicrosoft.com',
    signInPolicy: 'B2C_1A_SIGNUP_SIGNIN',
  },
  envName: Environment.Test,
  fecApiEndpoint: 'https://fecapi-test.aegispremier.com',
  production: false,
};
