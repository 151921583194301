import { Dates } from '../../../utils/dates';

export const FecMaxLength = {
  organizationName: 200,
  fecId: 9,
  partyCode: 3,
  partyType: 3,
  electionDescription: 20,

  contributorId: 24,

  firstName: 20,
  middleName: 20,
  lastName: 30,
  prefix: 10,
  suffix: 10,
  title: 20, // Title or Position

  employer: 38,
  occupation: 38,

  address1: 34,
  address2: 34,
  city: 30,
  state: 2,
  postalCode: 5,
  postalCodePlus4: 4,
  district: 2,

  phone: 10,
  email: 90,
  webUrl: 90,

  memoText: 100,
  form99Text: 19900,

  dollarAmount: 12,
  loanDueDateTerms: 15,
  interestRateTerms: 15,

  officeSought: 1,

  purpose: 100,

  transactionID: 20,
  backRefScheduleName: 8,
  date: 8,
  year: 4,

  collateralDescription: 100,
  futureIncomeDescription: 100,
  basisOfLoan: 100,

  longFormText: 4000,
};

export const FecRegexValidation = {
  emailAddress: '^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$',
};

export const DateValidation = {
  minDate: Dates.fromString('1900-01-01'),
  maxDate: Dates.fromString('2079-06-06'),
};

export const StringConstants = {
  /** Returns the string 'Active-Client-Id' */
  ActiveClientKey: 'Active-Client-Id',
};
