<div class="container mx-auto max-w-[1800px] box-border border-2">
  <app-header></app-header>
  <mat-sidenav-container class='default-container' autosize>
    <mat-sidenav mode="side" opened>
      <app-sidebar></app-sidebar>
    </mat-sidenav>
    <mat-sidenav-content class="p-2 apt-background-color">
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
  <app-footer></app-footer>
</div>