import { Environment } from 'src/app/shared/models/api/enum';
import { EnvironmentSettings } from 'src/app/shared/models/app/model';

export const environment: EnvironmentSettings = {
  appInsightsConnectionString:
    'InstrumentationKey=5bacd31a-4e28-4e2f-b4d3-d82893561bd2;IngestionEndpoint=https://westus2-2.in.applicationinsights.azure.com/;LiveEndpoint=https://westus2.livediagnostics.monitor.azure.com/',
  auth: {
    authorityDomain: 'aegispremieruat.b2clogin.com',
    clientId: '5358211d-7a27-4562-addd-b900831e0aa1', // Maps to Azure AD B2C application (client) ID
    domainName: 'aegispremieruat.onmicrosoft.com',
    signInPolicy: 'B2C_1A_SIGNUP_SIGNIN',
  },
  envName: Environment.UAT,
  fecApiEndpoint: 'https://fecapi-uat.aegispremier.com',
  production: true,
};
