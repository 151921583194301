import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { DefaultComponent } from './layouts/default/default.component';
import { EmptyComponent } from './layouts/empty/empty.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { ClientGuard } from './shared/guards/client.guard';
import { EnvironmentGuard } from './shared/guards/environment.guard';

const routes: Routes = [
  {
    // Dedicated route for redirects per best practice recommended here:
    // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/msal-guard.md#using-the-msal-guard-with-path-routing
    path: 'msal',
    component: MsalRedirectComponent,
  },
  {
    path: '',
    component: DefaultComponent,
    children: [
      {
        path: '',
        redirectTo: '/reports',
        pathMatch: 'full',
      },
      {
        path: 'reports',
        canActivate: [MsalGuard, AuthGuard, EnvironmentGuard, ClientGuard],
        loadChildren: () => import('./modules/reports/reports.module').then((m) => m.ReportsModule),
      },
    ],
  },
  {
    path: '',
    component: EmptyComponent,
    children: [
      {
        path: 'demo',
        loadChildren: () => import('./modules/demo/demo.module').then((m) => m.DemoModule),
      },
    ],
  },
  {
    path: '',
    component: DefaultComponent,
    children: [
      {
        path: 'settings',
        canActivate: [MsalGuard, AuthGuard, ClientGuard],
        loadChildren: () => import('./modules/settings/settings.module').then((m) => m.SettingsModule),
      },
    ],
  },
  {
    path: '',
    component: EmptyComponent,
    children: [
      {
        path: 'auth',
        loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule),
      },
    ],
  },
  {
    path: 'logout',
    redirectTo: 'auth/logout',
  },
  { path: '**', redirectTo: '/reports' },
];

// Would be nice to use BrowserService, but there doesn't seem to be a way to access it from this early in the app
const inRunningInIFrame = window !== window.parent && !window.opener;

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: !inRunningInIFrame ? 'enabledBlocking' : 'disabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
