import { Injectable } from '@angular/core';
import { Uri } from 'src/app/utils/uri';
import { QueryParams } from '../models/app/model';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root',
})
export class UriService {
  public constructor(private environmentService: EnvironmentService) {}

  public getUri(relativeUrl: string, queryParams?: QueryParams): Uri {
    const uri = new Uri(relativeUrl, this.environmentService.getEnvSettings().fecApiEndpoint);

    if (queryParams) {
      for (const [key, value] of Object.entries(queryParams)) {
        uri.addQueryParam(key, value.toString());
      }
    }
    return uri;
  }
}
