/**
 * These settings map the hostname of the running Angular app to the matching environment.XXX.ts file.
 * By default, when the app is running under localhost, it will use the settings in environment.dev.ts.
 * Ex 1: If the URL contains a value in uiHostnamesDev, then the settings in environment.dev.ts are used.
 * Ex 2: If 'localhost' is moved to uiHostnamesTest, then the locally running app will use the test API and
 * auth endpoints.
 * Ex 3: If 'localhost' is moved to uiHostnamesLocal, then the API calls will hit https://localhost:7107
 * (Note that authentication calls will continue to use dev because Azure does not support local B2C instances)
 */
export const environmentUrls = {
  uiHostnamesLocal: [] as string[], // Move 'localhost' here if you want to hit the APIs in a locally running VS instance
  uiHostnamesDev: ['localhost', 'fec-dev.aegispremier.com', 'kind-pond-07d9c7d1e.1.azurestaticapps.net'],
  uiHostnamesTest: ['fec-test.aegispremier.com', 'purple-mud-0414a5f1e.1.azurestaticapps.net'],
  uiHostnamesUat: ['fec-uat.aegispremier.com', 'thankful-beach-00e933c1e.1.azurestaticapps.net'],
  uiHostnamesProd: ['fec.aegispremier.com', 'lemon-mushroom-0f017dd1e.1.azurestaticapps.net'],
};
