import { ErrorHandler, Injectable } from '@angular/core';
import { ErrorHandlerService } from './error-handler.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  public constructor(private errorHandlerService: ErrorHandlerService) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public handleError(err: any): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    this.errorHandlerService.handleError(err);
  }
}
