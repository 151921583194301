import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { InjectionTokens } from 'src/app/utils/injection-tokens';

@Injectable({
  providedIn: 'root',
})
export class BrowserService {
  private readonly _isRenderingInBrowser: boolean;

  public constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(InjectionTokens.LOCATION) private location: Location,
    @Inject(InjectionTokens.WINDOW) private window: Window,
  ) {
    this._isRenderingInBrowser = isPlatformBrowser(platformId);
  }

  /** Indicates whether the current web application is running inside an iframe. */
  public isRunningInIFrame(): boolean {
    if (!this._isRenderingInBrowser) {
      return false;
    }

    return this.window !== this.window.parent && !this.window.opener;
  }

  /** Indicates whether the app is running within the context of an e2e test. Ideally we shouldn't have this
   *  function because our app shouldn't care whether or not it's an e2e test, but there are some rare cases
   *  where no other workaround has been found.
   */
  public isRunningInE2ETest(): boolean {
    if (!this._isRenderingInBrowser) {
      return false;
    }

    return Object.prototype.hasOwnProperty.call(this.window, 'Cypress');
  }

  public getLocationHostname(): string {
    if (!this._isRenderingInBrowser) {
      return '';
    }
    if (this.isRunningInE2ETest()) {
      return this.getMockedLocationHostname();
    }

    return this.location.hostname;
  }

  /**
   * Return a mocked value for location.hostname.
   * NOTE: We use this function ONLY when running a Cypress test, and we do it this way because
   * there does not appear to be a way to mock location.hostname from Cypress.
   */
  private getMockedLocationHostname(): string {
    // Modify this to trick Cypress into thinking it is running in other environments. The EnvironmentService
    // will use the value returned here to determine which environment.{env}.ts file to use for settings.
    // Ex: To run Cypress against the test API and B2C endpoints, use environmentUrls.uiHostnamesTest[0]
    return this.location.hostname;
  }
}
