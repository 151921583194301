<mat-toolbar color="primary" class="pl-3">
  <ng-container *ngIf="{sideBarState: sideBarState$ | async} as session">
    <button mat-icon-button class="pl-0" aria-label="Open menu" (click)="toggleSession(session.sideBarState || SideBarState.NotSet)">
      <mat-icon>menu</mat-icon>
    </button>
  </ng-container>
  <span><img src="assets/images/aegislogo.svg" alt="Aegis Premier Logo"></span>
  <span class="spacer"></span>
  <button mat-icon-button aria-label="More options" [matMenuTriggerFor]="menu">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="logout()">
      <span>Logout</span>
    </button>
  </mat-menu>
</mat-toolbar>