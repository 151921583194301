import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, NavigationError, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { RaygunService } from './shared/error-handling/raygun.service';
import { AppInsightsService } from './shared/services/app-insights.service';
import { BrowserService } from './shared/services/browser.service';
import { InjectionTokens } from './utils/injection-tokens';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  public title = 'Aegis FEC Filer';
  public showRouterOutlet = false;
  public isLoggedIn = false;
  private subscriptions = new Subscription();

  public constructor(
    private appInsightsService: AppInsightsService,
    private browserService: BrowserService,
    private raygunService: RaygunService,
    private router: Router,
    @Inject(InjectionTokens.WINDOW) private window: Window,
  ) {}

  public ngOnInit(): void {
    const routerEventsSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationError) {
        this.raygunService.logError('Navigation error', { event });
      }
      if (event instanceof NavigationEnd) {
        this.window.scrollTo(0, 0);
        this.raygunService.logNavigation(event.url);
      }
    });
    this.subscriptions.add(routerEventsSubscription);

    // This is to avoid reload during acquireTokenSilent() because of hidden iframe
    this.showRouterOutlet = !this.browserService.isRunningInIFrame() || this.browserService.isRunningInE2ETest();

    this.raygunService.setTags();
    this.appInsightsService.initialize();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
