import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, first, switchMap } from 'rxjs';
import { StringConstants } from '../models/app/constants';
import { CurrentUserRepository } from '../repositories/current-user.repository';
import { EnvironmentService } from '../services/environment.service';

@Injectable()
export class ClientInterceptor implements HttpInterceptor {
  public constructor(
    private currentUserRepository: CurrentUserRepository,
    private environmentService: EnvironmentService,
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isAuthenticatedUrl = this.isAuthenticatedUrl(request.url);

    if (!isAuthenticatedUrl) {
      return next.handle(request);
    }

    return this.currentUserRepository.currentUser$.pipe(
      first(), // We want this to only fire once so that the finalize runs in spinner-interceptor.ts
      switchMap((user) => {
        const requestWithClientId = request.clone({
          setHeaders: {
            [StringConstants.ActiveClientKey]: user.activeClientId,
          },
        });
        return next.handle(requestWithClientId);
      }),
    );
  }

  private isAuthenticatedUrl(url: string): boolean {
    const aegisEndpoint = this.environmentService.getEnvSettings().fecApiEndpoint;
    const anonymousAegisEndpoints = ['aegispremier.com/api/appinfo'];

    return (
      url.includes(aegisEndpoint) &&
      !anonymousAegisEndpoints.some((anonymousEndpoint) => url.includes(anonymousEndpoint))
    );
  }
}
