import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { DateFormat, DayJsInclusivity } from '../shared/models/app/enum';

dayjs.extend(customParseFormat);
dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(quarterOfYear);
dayjs.extend(timezone);
dayjs.extend(utc);

/**
 * Encapsulates date creation and handling
 */
export class Dates {
  /**
   * The default timezone for all dates. We set it to America/New_York because we want all dates to be
   * calculated as if the user was in that timezone.
   */
  private static TIMEZONE = 'America/New_York';

  /**
   * Returns a string representation of the date in the specified format.
   * @param date The date being formatted
   * @param format The format of the date string. Must be a valid dayjs format string.
   */
  public static format(date: dayjs.ConfigType, format: DateFormat = DateFormat.YYYYMMDD_Dash): string {
    return Dates.toDayJS(date).format(format);
  }

  /**
   * Returns true if date is between the given start and end dates.
   * See https://day.js.org/docs/en/plugin/is-between for reference.
   * Ex: Returns true if date='2022-05-15', startOfRange='2022-05-14' and endOfRange='2022-05-16'
   * @param date The date being checked
   * @param startOfRange The start of the date range to check
   * @param endOfRange The end of the date range to check
   * @param inclusivity Indicates whether to include the start and end of the date range when checking
   */
  public static isBetween(
    date: dayjs.ConfigType,
    startOfRange: dayjs.ConfigType,
    endOfRange: dayjs.ConfigType,
    inclusivity: DayJsInclusivity = DayJsInclusivity.InclusiveStartInclusiveEnd,
  ): boolean {
    return Dates.toDayJS(date).isBetween(Dates.toDayJS(startOfRange), Dates.toDayJS(endOfRange), 'day', inclusivity);
  }

  /**
   * Returns true if date is after dateToCompareTo regardless of time.
   * See https://day.js.org/docs/en/query/is-after for reference.
   * Ex: Returns true if date='2022-05-15' and dateToCompareTo='2022-05-01'
   * @param date The date you want to test.
   * @param dateToCompareTo The date you want to compare to.
   */
  public static isAfter(date: dayjs.ConfigType, dateToCompareTo: dayjs.ConfigType): boolean {
    return Dates.toDayJS(date).isAfter(Dates.toDayJS(dateToCompareTo), 'day');
  }

  /**
   * Returns true if date is same or after dateToCompareTo regardless of time.
   * See https://day.js.org/docs/en/query/is-same-or-after for reference.
   * Ex: Returns true if date='2022-05-15' and dateToCompareTo='2022-05-15'
   * @param date The date you want to test.
   * @param dateToCompareTo The date you want to compare to.
   */
  public static isSameOrAfter(date: dayjs.ConfigType, dateToCompareTo: dayjs.ConfigType): boolean {
    return Dates.toDayJS(date).isSameOrAfter(Dates.toDayJS(dateToCompareTo), 'day');
  }

  /**
   * Returns true if date is before dateToCompareTo regardless of time.
   * See https://day.js.org/docs/en/query/is-before for reference.
   * Ex: Returns false if date='2022-05-15' and dateToCompareTo='2022-05-01'
   * @param date The date you want to test.
   * @param dateToCompareTo The date you want to compare to.
   */
  public static isBefore(date: dayjs.ConfigType, dateToCompareTo: dayjs.ConfigType): boolean {
    return Dates.toDayJS(date).isBefore(Dates.toDayJS(dateToCompareTo), 'day');
  }

  /**
   * Returns true if date is same or before dateToCompareTo regardless of time.
   * See https://day.js.org/docs/en/query/is-same-or-before for reference.
   * Ex: Returns true if date='2022-05-15' and dateToCompareTo='2022-05-15'
   * @param date The date you want to test.
   * @param dateToCompareTo The date you want to compare to.
   */
  public static isSameOrBefore(date: dayjs.ConfigType, dateToCompareTo: dayjs.ConfigType): boolean {
    return Dates.toDayJS(date).isSameOrBefore(Dates.toDayJS(dateToCompareTo), 'day');
  }

  /**
   * Returns true if date matches dateToCompareTo regardless of time
   * See https://day.js.org/docs/en/query/is-same for reference.
   * @param date The date you want to test.
   * @param dateToCompareTo The date you want to compare to.
   */
  public static isSame(
    date: dayjs.ConfigType,
    dateToCompareTo: dayjs.ConfigType,
    unit: dayjs.QUnitType = 'day',
  ): boolean {
    return Dates.toDayJS(date).isSame(Dates.toDayJS(dateToCompareTo), unit);
  }

  /**
   * Converts from JavaScript Date to Dayjs with timezone.
   * @param date The date you want to convert.
   */
  public static fromDate(date: Date): dayjs.Dayjs {
    return dayjs(date).tz(Dates.TIMEZONE, true);
  }

  /**
   * Converts from ISO 8601 string to Dayjs with timezone.
   * @param date A date represented as an ISO 8601 string. Ex: '2022-05-19 09:30:26', '2022-05-19T00:00:00.000'
   */
  public static fromISOString(date: string): dayjs.Dayjs {
    return dayjs(date).tz(Dates.TIMEZONE);
  }

  /**
   * Returns a Date representing the specified date string. The time is set to midnight.
   * @param date The string formatted date (e.g. '2022-05-31')
   * @param format The format of the date string. Must be a valid dayjs format string.
   */
  public static fromString(date: string, format: DateFormat = DateFormat.YYYYMMDD_Dash): Date {
    const dayjsDate = dayjs.tz(date, format, Dates.TIMEZONE);
    return new Date(dayjsDate.year(), dayjsDate.month(), dayjsDate.date());
  }

  /**
   * Returns a Dayjs representing the current date and time in the America/New_York time zone.
   * Example: If executed in CO at 11 pm May 15 2022, it returns 1 am May 16 2022.
   */
  public static now(): dayjs.Dayjs {
    return dayjs().tz(Dates.TIMEZONE);
  }

  /**
   * Returns a number representing the current year in the America/New_York time zone.
   * Example 1: If executed in CO at 11 pm Dec 31 2022, it returns 2023.
   * Example 2: If executed on June 15 2023, it returns 2023.
   * @returns The current year in the America/New_York time zone.
   */
  public static year(): number {
    return Dates.now().year();
  }

  /**
   * Returns the number for the month. This is NOT zero-based.
   * @returns The month number for the current month (1: Jan, 2: Feb, etc.)
   */
  public static month(): number {
    return Dates.now().month() + 1;
  }

  /**
   * Gets the 1-based number for the previous month.
   * - If caller passes in < 1, then 12 is returned.
   * - If caller passes in > 12, then 11 is returned.
   * @returns The month number for the previous month (1: Jan, 2: Feb, etc.)
   */
  public static getPreviousMonth(currentMonth: number): number {
    if (currentMonth < 1 || currentMonth > 12) {
      throw new Error('currentMonth MUST be between 1 and 12.');
    }

    const previousMonth = currentMonth === 1 ? 12 : currentMonth - 1;
    return previousMonth;
  }

  /**
   * Returns the day number for the last day of the month.
   * @returns The day number for the last day of the month.
   */
  public static getLastDayOfMonth(currentMonth: number, currentYear: number): number {
    const monthsWith31days = [1, 3, 5, 7, 8, 10, 12];

    if (currentMonth < 1 || currentMonth > 12) {
      throw new Error('currentMonth MUST be between 1 and 12.');
    }

    if (currentMonth === 2) {
      if ((currentYear % 4 === 0 && currentYear % 100 !== 0) || currentYear % 400 === 0) {
        return 29; // Leap year
      } else {
        return 28;
      }
    }
    if (monthsWith31days.includes(currentMonth)) {
      return 31;
    }
    return 30;
  }

  /**
   * Returns the current month, day and year for the America/New_York time zone, expressed in
   * universal time.
   * Example: If executed in CO at 9 pm May 15 2022, it returns May 15 2022 12:00:00 am.
   * Example: If executed in CO at 11 pm May 15 2022, it returns May 16 2022 12:00:00 am.
   *
   * IMPORTANT: To extract the correct month, day and year, you must use the getUTC* methods.
   * Example: To get the day of the month, use todayDate().getUTCDate(). If you use getDate() on a
   * computer west of the UTC line, you will get yesterday's date. That's because javascript
   * stores the date relative to UTC, so a date like 2022-05-15 00:00:00 in Colorado is actually
   * 2022-05-14 18:00:00 GMT -0600 (Mountain Daylight Time)
   */
  public static todayDate(): Date {
    const now = Dates.now();
    const today = new Date(Date.UTC(now.year(), now.month(), now.date()));
    return today;
  }

  /**
   * Returns the current month, day and year for the America/New_York time zone as an ISO 8601 string
   * without the UTC indicator (that is, it does not have the 'Z' at the end).
   * Example: If executed in CO at 9 pm May 15 2022, it returns 2022-05-15T00:00:00.
   * Example: If executed in CO at 11 pm May 15 2022, it returns 2022-05-16T00:00:00.
   * @returns The current date in ISO 8601 format (e.g. '2022-05-15T00:00:00')
   */
  public static todayISOString(): string {
    return Dates.toISOString(Dates.todayDate());
  }

  /**
   * Returns the specified date as an ISO 8601 string without the UTC indicator (that is, it does not have the 'Z' at the end).
   * @param date The date you want to convert.
   * @returns The date in ISO 8601 format (e.g. '2022-05-15T00:00:00')
   */
  public static toISOString(date: dayjs.ConfigType): string {
    // Note that we strip off the 'Z' at the end to remove the UTC indicator
    return Dates.toDayJS(date).toISOString().slice(0, 23);
  }

  /**
   * Returns the specified date as an ISO 8601 string without the UTC indicator (that is, it does not have the 'Z' at the end).
   * @param year The year (e.g. 2023 for the year 2023)
   * @param month The month (e.g. 1 for January, 2 for February, etc.). Note that this is one-based, which is different
   * than the zero-based month in JavaScript Date.
   * @param day The day of the month (e.g. 1 for the first day of the month, 2 for the second day of the month, etc.)
   * @returns The date in ISO 8601 format (e.g. '2022-05-15T00:00:00')
   */
  public static getDateAsISOString(year: number, month: number, day: number): string {
    return Dates.toISOString(new Date(Date.UTC(year, month - 1, day)));
  }

  private static toDayJS(date: dayjs.ConfigType): dayjs.Dayjs {
    if (dayjs.isDayjs(date)) {
      return date.tz(Dates.TIMEZONE);
    }
    return dayjs.tz(date, Dates.TIMEZONE);
  }

  /**
   * Returns the current date/time in utc as dayjs.DayJs.
   * Example: If this current date/time MST is 2023-05-14 18:00:00.0000000, it returns 2023-05-15 01:00:00.0000000 as Dayjs.
   */
  public static nowUtc(): dayjs.Dayjs {
    return dayjs().utc();
  }

  /**
   * Return the specified timestamp as a date formatted as YYYY-MM-DD in the user's time zone.
   * Example: If utcDateTime is "2023-05-17T01:01:47Z", it returns 2023-05-16 if the user's browser is in North America.
   * Example: If utcDateTime is "2023-05-17T22:01:47Z", it returns 2023-05-17 if the user's browser is in North America.
   * @param utcDateTime A date/time in ISO 8601 format (e.g. '2023-05-17T16:01:47Z')
   */
  public static toLocalDateString(utcDateTime: string) {
    const tZone = dayjs.tz.guess();
    const returnVal = dayjs(utcDateTime).tz(tZone, true);
    return returnVal.format(DateFormat.YYYYMMDD_Dash).toString();
  }
}
