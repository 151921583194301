import { NgModule } from '@angular/core';
import { DecimalsOnlyDirective } from './decimals-only.directive';
import { DisableAfterClickDirective } from './disable-after-click.directive';
import { DisableFormControlDirective } from './disable-form-control.directive';
import { DisableFormGroupDirective } from './disable-form-group.directive';
import { IntegersOnlyDirective } from './integers-only.directive';
import { TrimValueDirective } from './trim-value.directive';

@NgModule({
  declarations: [
    DecimalsOnlyDirective,
    DisableAfterClickDirective,
    DisableFormControlDirective,
    DisableFormGroupDirective,
    IntegersOnlyDirective,
    TrimValueDirective,
  ],
  exports: [
    DecimalsOnlyDirective,
    DisableAfterClickDirective,
    DisableFormControlDirective,
    DisableFormGroupDirective,
    IntegersOnlyDirective,
    TrimValueDirective,
  ],
})
export class DirectivesModule {}
