import { Injectable } from '@angular/core';
import { createState, Store } from '@ngneat/elf';
import { selectActiveEntity, setActiveId, setEntities, withActiveId, withEntities } from '@ngneat/elf-entities';
import { localStorageStrategy, persistState } from '@ngneat/elf-persist-state';
import { filter, map } from 'rxjs';
import { StorageItemKey } from '../models/app/enum';
import { CurrentUser } from '../models/app/model';

@Injectable({
  providedIn: 'root',
})
export class CurrentUserRepository {
  public readonly currentUserId = 'currentUser';
  private readonly state = createState(withEntities<CurrentUser, 'userId'>({ idKey: 'userId' }), withActiveId());
  private readonly store = new Store({ name: this.currentUserId, state: this.state.state, config: this.state.config });

  // Cache the user in local storage. This allows the user's active client (stored in CurrentUser.ActiveClientId)
  // to survive browser refreshes. We use localStorage instead of sessionStorage so that the login is shared across tabs.
  private readonly persist = persistState(this.store, {
    key: StorageItemKey.CurrentUser,
    storage: localStorageStrategy,
  });

  /** Indicates whether a user is currently logged in to the application. Requires that setCurrentUser be called
   *  with a non-empty userId.
   */
  public readonly isLoggedIn$ = this.store.pipe(selectActiveEntity()).pipe(map((currentUser) => !!currentUser?.userId));

  /** Gets the logged on user from the store. Dev note: Be sure the object has been inserted into the store with
   *  setCurrentUser() prior to accessing this property. */
  public readonly currentUser$ = this.store.pipe(selectActiveEntity()).pipe(
    filter((currentUser) => !!currentUser),
    map((currentUser) => currentUser as CurrentUser),
  );

  /** Insert the specified user into the store, overwriting any existing items. The data exist only in the browser's
   *  memory and is released when the user navigates to another app or does a browser refresh.
   */
  public setCurrentUser(currentUser: CurrentUser) {
    this.store.update(setEntities([currentUser]), setActiveId(currentUser.userId));
  }

  /** Clears out the user stored in this repository. */
  public clearUser() {
    this.store.reset();
  }
}
