import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, of, switchMap, tap } from 'rxjs';
import { AppInfo } from 'src/app/shared/models/api/model';
import { AppInfoRepository } from 'src/app/shared/repositories/app-info.repository';
import { RaygunService } from '../../error-handling/raygun.service';
import { Environment } from '../../models/api/enum';
import { UriService } from '../uri.service';

@Injectable({
  providedIn: 'root',
})
export class AppInfoService {
  public constructor(
    private http: HttpClient,
    private appInfoRepository: AppInfoRepository,
    private raygunService: RaygunService,
    private uriService: UriService,
  ) {}

  /** Returns information about the currently running application. If the information does not exist in
   * the browser's cache, it is retrieved from the server and inserted into the cache prior to returning. */
  public getAppInfo(): Observable<AppInfo> {
    // return of({ versionNumber: '0.1.0' });

    const uri = this.uriService.getUri('/appinfo');
    return this.http.get<AppInfo>(uri.toString()).pipe(
      tap((appInfo) => this.appInfoRepository.setActiveAppInfo(appInfo)),
      this.appInfoRepository.skipWhileAppInfosCached(this.appInfoRepository.appInfoId, { returnSource: of(null) }), // Needs returnSource or else control won't pass to next line when cache is populated
      switchMap(() => this.appInfoRepository.activeAppInfo$),
      catchError((err) => {
        this.raygunService.logError(err as Error);
        return of({
          versionNumber: '<unknown>',
          environment: Environment.Unknown,
          buildDate: 'Unknown',
          buildVersion: '<unknown>',
        } as AppInfo);
      }),
    );
  }
}
