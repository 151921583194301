import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable, map } from 'rxjs';
import { CurrentUserRepository } from '../repositories/current-user.repository';

@Injectable({
  providedIn: 'root',
})
export class ClientGuard {
  public constructor(
    private currentUserRepository: CurrentUserRepository,
    private router: Router,
  ) {}

  public canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // Verify that the user has a client ID set in the activeClientId property of the user object.
    // If not, and the clientIds claim is empty, redirect to the no-client page.
    // If the user has access to more than one client, redirect to the select-client page.
    return this.currentUserRepository.currentUser$.pipe(
      map((user) => {
        if (user.activeClientId || user.clientIds.length === 1) {
          return true;
        } else if (user.clientIds.length <= 0) {
          return this.router.parseUrl('/auth/no-client');
        } else {
          return this.router.parseUrl('/auth/select-client');
        }
      }),
    );
  }
}
