import { NgModule } from '@angular/core';
import { AmendmentTextPipe } from './amendment-text.pipe';
import { CategoryCodeEnumTextPipe } from './category-code-enum-text.pipe';
import { CommitteeTypeTextPipe } from './committee-type-text.pipe';
import { ElectionTypeByFormSubtypeEnumText } from './election-type-by-form-subtype-enum-text.pipe';
import { ElectionTypeEnumTextPipe } from './election-type-enum-text.pipe';
import { EntityTypeEnumTextPipe } from './entity-type-enum-text.pipe';
import { EnumKeyToValuePipe } from './enum-key-to-value.pipe';
import { FecFileStatusEnumCssClassPipe } from './fec-file-status-enum-css-class.pipe';
import { FecFileStatusEnumTextPipe } from './fec-file-status-enum-text.pipe';
import { FecFormSubtypeTextPipe } from './fec-form-subtype-enum-text.pipe';
import { FecFormTypeEnumTextPipe } from './fec-form-type-enum-text.pipe';
import { Form1AffiliatedRelationshipsEnumTextPipe } from './form1-affiliated-relationships-enum-text.pipe';
import { Form99DocTypeEnumTextPipe } from './form99-doc-type-enum-text.pipe';
import { ImportStatusProgressMessagePipe } from './import-status-progress-message.pipe';
import { OfficeSoughtEnumTextPipe } from './office-sought-enum-text.pipe';
import { OrganizationTypeTextPipe } from './organization-type-text.pipe';
import { PartyTypeTextPipe } from './party-type-text.pipe';
import { ReportTypeCategoryEnumTextPipe } from './report-type-category-enum-text.pipe';
import { ScheduleLineDisplayTextPipe } from './schedule-line-display-text.pipe';
import { ScheduleLineEnumTextPipe } from './schedule-line-enum-text.pipe';
import { SortByColumnEnumTextPipe } from './sort-by-column-enum-text.pipe';

@NgModule({
  declarations: [
    AmendmentTextPipe,
    CategoryCodeEnumTextPipe,
    CommitteeTypeTextPipe,
    ElectionTypeByFormSubtypeEnumText,
    ElectionTypeEnumTextPipe,
    EntityTypeEnumTextPipe,
    EnumKeyToValuePipe,
    FecFileStatusEnumTextPipe,
    FecFileStatusEnumCssClassPipe,
    FecFormTypeEnumTextPipe,
    FecFormSubtypeTextPipe,
    Form1AffiliatedRelationshipsEnumTextPipe,
    Form99DocTypeEnumTextPipe,
    ImportStatusProgressMessagePipe,
    OfficeSoughtEnumTextPipe,
    OrganizationTypeTextPipe,
    PartyTypeTextPipe,
    ReportTypeCategoryEnumTextPipe,
    ScheduleLineDisplayTextPipe,
    ScheduleLineEnumTextPipe,
    SortByColumnEnumTextPipe,
  ],
  exports: [
    AmendmentTextPipe,
    CategoryCodeEnumTextPipe,
    CommitteeTypeTextPipe,
    ElectionTypeByFormSubtypeEnumText,
    ElectionTypeEnumTextPipe,
    EntityTypeEnumTextPipe,
    EnumKeyToValuePipe,
    FecFileStatusEnumTextPipe,
    FecFileStatusEnumCssClassPipe,
    FecFormTypeEnumTextPipe,
    FecFormSubtypeTextPipe,
    Form1AffiliatedRelationshipsEnumTextPipe,
    Form99DocTypeEnumTextPipe,
    ImportStatusProgressMessagePipe,
    OfficeSoughtEnumTextPipe,
    OrganizationTypeTextPipe,
    PartyTypeTextPipe,
    ReportTypeCategoryEnumTextPipe,
    ScheduleLineDisplayTextPipe,
    ScheduleLineEnumTextPipe,
    SortByColumnEnumTextPipe,
  ],
})
export class PipesModule {}
