import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Environment } from '../models/api/enum';
import { EnvironmentService } from '../services/environment.service';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentGuard {
  public constructor(
    private envService: EnvironmentService,
    private router: Router,
  ) {}

  public canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.envService.getEnvSettings().envName === Environment.Unknown) {
      return this.router.parseUrl('/auth/no-environment');
    }

    return true;
  }
}
