import { Injectable } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AccountInfo, AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { Observable, filter, merge, switchMap, tap } from 'rxjs';
import { RaygunService } from '../error-handling/raygun.service';
import { AzureB2CClaims, CurrentUser } from '../models/app/model';
import { CurrentUserRepository } from '../repositories/current-user.repository';
import { AppInsightsService } from './app-insights.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  public constructor(
    private appInsightsService: AppInsightsService,
    private authService: MsalService,
    private currentUserRepository: CurrentUserRepository,
    private msalBroadcastService: MsalBroadcastService,
    private raygunService: RaygunService,
  ) {}

  /** Initialize the authentication service and return a value indicating whether a user is logged in. */
  public initialize(): Observable<boolean> {
    const msalSubject$ = this.msalBroadcastService.msalSubject$.pipe(
      filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      tap((result) => {
        // User just logged in - Tell MSAL and store in cache
        const payload = result.payload as AuthenticationResult;
        const account = payload.account;
        if (account) {
          this.authService.instance.setActiveAccount(account);
          this.storeInRepository(account);
        }
      }),
    );

    // Listen for authentication related events and take action, if needed
    const inProgress$ = this.msalBroadcastService.inProgress$.pipe(
      filter((status) => status === InteractionStatus.None),
      tap(() => {
        let account = this.authService.instance.getActiveAccount();
        const allAccounts = this.authService.instance.getAllAccounts();
        if (!account && allAccounts.length > 0) {
          account = allAccounts[0];
          this.authService.instance.setActiveAccount(allAccounts[0]);
        }
      }),
    );

    return merge(msalSubject$, inProgress$).pipe(switchMap(() => this.currentUserRepository.isLoggedIn$));
  }

  public logout() {
    this.currentUserRepository.clearUser();
    this.raygunService.clearUser();
    this.appInsightsService.clearUser();
    this.authService.logoutRedirect();
  }

  private storeInRepository(account: AccountInfo) {
    const claims = account.idTokenClaims as unknown as AzureB2CClaims;
    const clientIds = claims.clientIds?.length ? claims.clientIds.split(',') : [];
    const activeClientId = clientIds.length === 1 ? clientIds[0] : '';

    const currentUser: CurrentUser = {
      userId: account.localAccountId,
      userName: account.username || claims.email || claims.emails?.[0] || '<unknown>',
      firstName: claims.given_name || '<unknown>',
      lastName: claims.family_name || '<unknown>',
      fullName: claims.name || '<unknown>',
      activeClientId: activeClientId,
      clientIds: clientIds,
    };
    this.currentUserRepository.setCurrentUser(currentUser);
    this.raygunService.setUser(currentUser.userName, currentUser.fullName);
    this.appInsightsService.setCurrentUser(currentUser);
  }
}
