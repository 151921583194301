export enum Environment {
  Unknown = 'Unknown',
  Local = 'Local',
  Development = 'Development',
  Test = 'Test',
  UAT = 'UAT',
  Production = 'Production',
}

export enum OrganizationType {
  Unknown = 'Unknown',
  ///<summary>"C - Corporation"</summary>
  C = 'C',
  ///<summary>"L - Labor Organization"</summary>
  L = 'L',
  ///<summary>"M - Membership Organization"</summary>
  M = 'M',
  ///<summary>"T - Trade Association"</summary>
  T = 'T',
  ///<summary>"V - Cooperative"</summary>
  V = 'V',
  ///<summary>"W - Corporation W/O Capital Stock"</summary>
  W = 'W',
}

export enum CommitteeType {
  ///<summary>"Unknown"</summary>
  Unknown = 'Unknown',
  ///<summary>"A - Principal Campaign Committee"</summary>
  A = 'A',
  ///<summary>"B - Authorized Committee"</summary>
  B = 'B',
  ///<summary>"C - Support/Oppose One Candidate (Not Authoized Committee)"</summary>
  C = 'C',
  ///<summary>"D - Party Committee"</summary>
  D = 'D',
  ///<summary>"E - Separate Segregated Fund"</summary>
  E = 'E',
  ///<summary>"F - Support/Oppose More Than One Federal Cand & Not Segregated Fund/Party"</summary>
  F = 'F',
  ///<summary>"G - Joint Fundraising Representative (At least One Is Authorized)"</summary>
  G = 'G',
  ///<summary>"H - Joint Fundraising Representative (None Are Authorized)"</summary>
  H = 'H',
}

export enum EntityType {
  ///<summary>Unknown or not yet specified</summary>
  Unknown = 'Unknown',
  /// <summary>Individual</summary>
  IND = 'IND',
  /// <summary>Candidate</summary>
  CAN = 'CAN',
  /// <summary>Committee</summary>
  COM = 'COM',
  /// <summary>Campaign Committee</summary>
  CCM = 'CCM',
  /// <summary>Party Committee</summary>
  PTY = 'PTY',
  /// <summary>Political Action Committee</summary>
  PAC = 'PAC',
  /// <summary>Organization</summary>
  ORG = 'ORG',
}

export enum Form99DocType {
  ///<summary>Disavowal Response</summary>
  MSI = 'MSI',
  ///<summary>Filing Frequency Change Notice</summary>
  MSM = 'MSM',
  ///<summary>Miscellaneous Report To FEC</summary>
  MST = 'MST',
}

export enum States {
  AL = 'Alabama',
  AK = 'Alaska',
  AZ = 'Arizona',
  AR = 'Arkansas',
  CA = 'California',
  CO = 'Colorado',
  CT = 'Connecticut',
  DE = 'Delaware',
  DC = 'District of Columbia',
  FL = 'Florida',
  GA = 'Georgia',
  HI = 'Hawaii',
  ID = 'Idaho',
  IL = 'Illinois',
  IN = 'Indiana',
  IA = 'Iowa',
  KS = 'Kansas',
  KY = 'Kentucky',
  LA = 'Louisiana',
  ME = 'Maine',
  MD = 'Maryland',
  MA = 'Massachusetts',
  MI = 'Michigan',
  MN = 'Minnesota',
  MS = 'Mississippi',
  MO = 'Missouri',
  MT = 'Montana',
  NE = 'Nebraska',
  NV = 'Nevada',
  NH = 'New Hampshire',
  NJ = 'New Jersey',
  NM = 'New Mexico',
  NY = 'New York',
  NC = 'North Carolina',
  ND = 'North Dakota',
  OH = 'Ohio',
  OK = 'Oklahoma',
  OR = 'Oregon',
  PA = 'Pennsylvania',
  RI = 'Rhode Island',
  SC = 'South Carolina',
  SD = 'South Dakota',
  TN = 'Tennessee',
  TX = 'Texas',
  UT = 'Utah',
  VT = 'Vermont',
  VA = 'Virginia',
  WA = 'Washington',
  WV = 'West Virginia',
  WI = 'Wisconsin',
  WY = 'Wyoming',
}

export enum FecFormFilingStatus {
  /// <summary>The form is in draft mode and has not been validated or submitted to the FEC.</summary>
  Draft = 'Draft',
  /// <summary>The form is currently being checked for data integrity issues by an Aegis web job.</summary>
  Validating = 'Validating',
  /// <summary>The form has serious data integrity issues that prevent it from being submitted to the FEC.</summary>
  ValidatedHasErrors = 'ValidatedHasErrors',
  /// <summary>The form has mild data integrity issues but can still be submitted to the FEC.</summary>
  ValidatedHasWarnings = 'ValidatedHasWarnings',
  /// <summary>Something has gone wrong while trying to validate the form.</summary>
  ValidationFailed = 'ValidationFailed',
  /// <summary>The form has been validated and has no warnings or errors. It can be submitted to the FEC.</summary>
  Validated = 'Validated',
  /// <summary>The form is currently being uploaded to the FEC.</summary>
  FilingInProgress = 'FilingInProgress',
  /// <summary>The form has been successfully uploaded to the FEC and is currently being analyzed by the FEC.</summary>
  FecValidating = 'FecValidating',
  /// <summary>The FEC rejected a form or there was some kind of error when transmitting the file.</summary>
  FilingFailed = 'FilingFailed',
  /// <summary>The form has been successfully filed with the FEC.</summary>
  Filed = 'Filed',
}

export enum FecFormType {
  /// <summary>Statement of Organization</summary>
  Form1 = 'Form1',
  /// <summary>Notification of Multicandidate Status</summary>
  Form1M = 'Form1M',
  /// <summary>Statement of Candidacy</summary>
  Form2 = 'Form2',
  /// <summary>Independent expenditure reports and notices</summary>
  Form24 = 'Form24',
  /// <summary>Receipts and Disbursements</summary>
  Form3 = 'Form3',
  /// <summary>Receipts and Disbursements</summary>
  Form3P = 'Form3P',
  /// <summary>Contributions Bundled by Lobbyists/Registrants and Lobbyist/Registrant PACs</summary>
  Form3L = 'Form3L',
  /// <summary>Receipts and Disbursements</summary>
  Form3X = 'Form3X',
  /// <summary>Receipts and Disbursements</summary>
  Form4 = 'Form4',
  /// <summary>Independent Expenditures Made and Contributions Received</summary>
  Form5 = 'Form5',
  /// <summary>48-Hour Notice of Contributions/Loans Received</summary>
  Form6 = 'Form6',
  /// <summary>Communication Costs by Corporations and Membership Organizations</summary>
  Form7 = 'Form7',
  /// <summary>Debt Settlement Plan</summary>
  Form8 = 'Form8',
  /// <summary>24-Hour Notice of Disbursements/Obligations for Electioneering Communications</summary>
  Form9 = 'Form9',
  /// <summary>Expenditure of personal funds notices</summary>
  Form10 = 'Form10',
  /// <summary>Opposition personal funds notices</summary>
  Form11 = 'Form11',
  /// <summary>Suspension of increased limits notices</summary>
  Form12 = 'Form12',
  /// <summary>Donations Accepted for Inaugural Committee</summary>
  Form13 = 'Form13',
  /// <summary>Miscellaneous Report to the FEC</summary>
  Form99 = 'Form99',
  /// <summary>Request For Additional Information</summary>
  RFAI = 'RFAI',
}

export enum Form3XReportType {}

export enum FecFormSubtype {
  Unknown = 'Unknown',

  ///<summary>Form 24 - 24 Hour Report</summary>
  Form24_24Hour = 'Form24_24Hour',
  ///<summary>Form 24 - 48 Hour Report</summary>
  Form24_48Hour = 'Form24_48Hour',

  ///<summary>Form 3 - April 15 Quarterly Report</summary>
  Form3_Q1 = 'Form3_Q1',
  ///<summary>Form 3 - July 15 Quarterly not Semi-annual Report</summary>
  Form3_Q2 = 'Form3_Q2',
  ///<summary>Form 3 - July 15 Quarterly and Semi-annual Report</summary>
  Form3_Q2S = 'Form3_Q2S',
  ///<summary>Form 3 - Semi-Annual only (no Q2 – no coverage dates)</summary>
  Form3_QSA = 'Form3_QSA',
  ///<summary>Form 3 - MY - Mid Year - July 31</summary>
  Form3_QMY = 'Form3_QMY',
  ///<summary>Form 3 - July 31 Mid-Year (MY) report (with coverage dates) and Semi-annual</summary>
  Form3_QMS = 'Form3_QMS',
  ///<summary>Form 3 - October 15 Quarterly Report</summary>
  Form3_Q3 = 'Form3_Q3',
  ///<summary>Form 3 - YE - Year End - Jan 31</summary>
  Form3_QYE = 'Form3_QYE',
  ///<summary>Form 3 - Jan 31 Year End (YE) report (with coverage dates) and Semi-Annual</summary>
  Form3_QYS = 'Form3_QYS',

  ///<summary>Form 3 - Jan 31 Year-End Report</summary>
  Form3_YE = 'Form3_YE',
  ///<summary>Form 3 - July 31 Mid-Year Report</summary>
  Form3_MY = 'Form3_MY',
  ///<summary>Form 3 - Jan 31 Year End (MYE) report (with coverage dates) and Semi-annual</summary>
  Form3_MYS = 'Form3_MYS',
  ///<summary>Semi-Annual only (No MYE – no coverage dates)</summary>
  Form3_MSY = 'Form3_MSY',
  ///<summary>Form 3 - Monthly Feb 20 Report</summary>
  Form3_M2 = 'Form3_M2',
  ///<summary>Form 3 - Monthly Mar 20 Report</summary>
  Form3_M3 = 'Form3_M3',
  ///<summary>Form 3 - Monthly Apr 20 Report</summary>
  Form3_M4 = 'Form3_M4',
  ///<summary>Form 3 - Monthly May 20 Report</summary>
  Form3_M5 = 'Form3_M5',
  ///<summary>Form 3 - Monthly Jun 20 Report</summary>
  Form3_M6 = 'Form3_M6',
  ///<summary>Form 3 - Monthly Jul 20 and/or Semi-annual Report</summary>
  Form3_M7 = 'Form3_M7',
  ///<summary>Form 3 - July 20 (M7) Monthly report (with coverage dates) and Semi-Annual</summary>
  Form3_M7S = 'Form3_M7S',
  ///<summary>Form 3 - Semi-Annual only (No M7 -no coverage dates)</summary>
  Form3_MSA = 'Form3_MSA',
  ///<summary>Form 3 - Monthly Aug 20 Report</summary>
  Form3_M8 = 'Form3_M8',
  ///<summary>Form 3 - Monthly Sep 20 Report</summary>
  Form3_M9 = 'Form3_M9',
  ///<summary>Form 3 - Monthly Oct 20 Report</summary>
  Form3_M10 = 'Form3_M10',
  ///<summary>Form 3 - Monthly Nov 20 Report</summary>
  Form3_M11 = 'Form3_M11',
  ///<summary>Form 3 - Monthly Dec 20 Report</summary>
  Form3_M12 = 'Form3_M12',
  ///<summary>Form 3 - Monthly Jan 31 and/or Semi-annual Report</summary>
  Form3_MYE = 'Form3_MYE',

  ///<summary>Form 3 - 12-Day Pre-election Convention Report</summary>
  Form3_12C = 'Form3_12C',
  ///<summary>Form 3 - 12-Day Pre-election General Report</summary>
  Form3_12G = 'Form3_12G',
  ///<summary>Form 3 - 12-Day Pre-election Primary Report</summary>
  Form3_12P = 'Form3_12P',
  ///<summary>Form 3 - 12-Day Pre-election Runoff Report</summary>
  Form3_12R = 'Form3_12R',
  ///<summary>Form 3 - 12-Day Pre-election Special Report</summary>
  Form3_12S = 'Form3_12S',

  ///<summary>Form 3 - 30-Day Post-election General Report</summary>
  Form3_30G = 'Form3_30G',
  ///<summary>Form 3 - 30-Day Post-election Runoff Report</summary>
  Form3_30R = 'Form3_30R',
  ///<summary>Form 3 - 30-Day Post-election Special Report</summary>
  Form3_30S = 'Form3_30S',

  ///<summary>60 Day Post Convention of a Presidential Host Committee</summary>
  Form3_60D = 'Form3_60D',

  ///<summary>90 Day Post Inaugural Report by an Inaugural Committee</summary>
  Form3_90D = 'Form3_90D',
  ///<summary>Supplement to a 90D Inaugural Report</summary>
  Form3_90S = 'Form3_90S',

  ///<summary>Termination Report</summary>
  TER = 'TER',
}

export enum AffiliatedRelationship {
  ///<summary>Unknown or not yet specified</summary>
  Unknown = 'Unknown',
  ///<summary>ORG - Connected Organization</summary>
  ORG = 'ORG',
  ///<summary>AFF - Affiliated Committee</summary>
  AFF = 'AFF',
  ///<summary>JFR - Joint Fundraising Participant</summary>
  JFR = 'JFR',
  ///<summary>LPS - Leadership PAC Sponsor</summary>
  LPS = 'LPS',
}

export enum PartyType {
  ///<summary>Unknown or not yet specified</summary>
  Unknown = 'Unknown',
  ///<summary>National/summary>
  NAT = 'NAT',
  ///<summary>State</summary>
  STA = 'STA',
  ///<summary>Subordinate</summary>
  SUB = 'SUB',
}

export enum ReportType {
  ///<summary>Unknown or not yet specified</summary>
  Unknown = 'Unknown',
  ///<summary> ---- QUARTERLY REPORT TYPES ---- </summary>
  ///<summary>Q1 - April 15</summary>
  Q1 = 'Q1',
  ///<summary>Q2 - July 15</summary>
  Q2 = 'Q2',
  ///<summary>Q3 - Oct 15</summary>
  Q3 = 'Q3',
  ///<summary>MY - Mid Year - July 31</summary>
  MY = 'MY',

  ///<summary> ---- MONTHLY REPORT TYPES ---- </summary>
  ///<summary>M2 - Feb 20</summary>
  M2 = 'M2',
  ///<summary>M3 - Mar 20</summary>
  M3 = 'M3',
  ///<summary>M4 - Apr 20</summary>
  M4 = 'M4',
  ///<summary>M5 - May 20</summary>
  M5 = 'M5',
  ///<summary>M6 - Jun 20</summary>
  M6 = 'M6',
  ///<summary>M7 - Jul 20</summary>
  M7 = 'M7',
  ///<summary>M8 - Aug 20</summary>
  M8 = 'M8',
  ///<summary>M9 - Sep 20</summary>
  M9 = 'M9',
  ///<summary>M10 - Oct 20</summary>
  M10 = 'M10',
  ///<summary>M11 - Nov 20</summary>
  M11 = 'M11',
  ///<summary>M12 - Dec 20</summary>
  M12 = 'M12',

  ///<summary> ---- 12 DAY REPORT TYPES ---- </summary>
  ///<summary>12P - Primary</summary>
  P12 = 'P12',
  ///<summary>12G - General</summary>
  G12 = 'G12',
  ///<summary>12R - Runoff</summary>
  R12 = 'R12',
  ///<summary>12S - Special</summary>
  S12 = 'S12',
  ///<summary>12C - Convention</summary>
  C12 = 'C12',

  ///<summary> ---- 30 DAY REPORT TYPES ---- </summary>
  ///<summary>30G - General</summary>
  G30 = 'G30',
  ///<summary>30R - Runoff</summary>
  R30 = 'R30',
  ///<summary>30S - Special</summary>
  S30 = 'S30',

  ///<summary> ---- SHARED REPORT TYPES ---- </summary>
  ///<summary>YE - Year End - Jan 31</summary>
  YE = 'YE',
}

export enum ElectionType {
  ///<summary>Unknown or not yet specified</summary>
  Unknown = 'Unknown',
  ///<summary>General - G</summary>
  G = 'G',
  ///<summary>Primary - P</summary>
  P = 'P',
  ///<summary>Runoff - R</summary>
  R = 'R',
  ///<summary>Special - S</summary>
  S = 'S',
  ///<summary>Convention - C</summary>
  C = 'C',
  ///<summary>Recount - E</summary>
  E = 'E',
  ///<summary>Other - O</summary>
  O = 'O',
}

export enum DisbursementCategoryCode {
  ///<summary>Unknown or not yet specified</summary>
  Unknown = 'Unknown',
  ///<summary>Admin</summary>
  Admin = 'Admin',
  ///<summary>Travel</summary>
  Travel = 'Travel',
  ///<summary>Solicitation</summary>
  Solicitation = 'Solicitation',
  ///<summary>Advertising</summary>
  Advertising = 'Advertising',
  ///<summary>Polling</summary>
  Polling = 'Polling',
  ///<summary>CampaignMaterials</summary>
  CampaignMaterials = 'CampaignMaterials',
  ///<summary>CampaignEvent</summary>
  CampaignEvent = 'CampaignEvent',
  ///<summary>Transfer</summary>
  Transfer = 'Transfer',
  ///<summary>Loan</summary>
  Loan = 'Loan',
  ///<summary>Refund</summary>
  Refund = 'Refund',
  ///<summary>PoliticalContribution</summary>
  PoliticalContribution = 'PoliticalContribution',
  ///<summary>Donation</summary>
  Donation = 'Donation',
  ///<summary>Expenses that are not allocable</summary>
  PresidentialNonAllocable = 'PresidentialNonAllocable',
  ///<summary>Media expenditures</summary>
  PresidentialMedia = 'PresidentialMedia',
  ///<summary>Expenditures for mass mailings and other campaign materials (e.g., buttons, bumper stickers, brochures, mass mailings, pens, posters, balloons)</summary>
  PresidentialMailings = 'PresidentialMailings',
  ///<summary>Overhead expenditures of state offices and their facilities (e.g., rent, staff salaries, postage, office supplies, equipment, furniture, ballot access fees, petition drives, party fees and legal and accounting expenses)</summary>
  PresidentialOfficeOverhead = 'PresidentialOfficeOverhead',
  ///<summary>Expenditures for special telephone programs</summary>
  PresidentialTelephoneProgram = 'PresidentialTelephoneProgram',
  ///<summary>Public opinion poll expenditures</summary>
  PresidentialOpinionPoll = 'PresidentialOpinionPoll',
  ///<summary>Fundraising expenditures (e.g., costs for direct mail solicitations & fundraising events including printing, mailing lists, consultant fees, call lists, invitations, catering costs and room rental)</summary>
  PresidentialFundraising = 'PresidentialFundraising',
}

export enum OfficeSought {
  ///<summary>Unknown or not yet specified</summary>
  Unknown = 'Unknown',
  ///<summary>House</summary>
  H = 'H',
  ///<summary>Senate</summary>
  S = 'S',
  ///<summary>Presidential</summary>
  P = 'P',
}

export enum ScheduleLine {
  ///<summary>Unknown or not yet specified</summary>
  Unknown = 'Unknown',
  ///<summary>11ai. Individual Itemized</summary>
  SA11AI = 'SA11AI',
  ///<summary>11aii. Individual Unitemized</summary>
  SA11AII = 'SA11AII',
  ///<summary>11b. Political Party Committee</summary>
  SA11B = 'SA11B',
  ///<summary>11c. Other Political Commitee (PAC)</summary>
  SA11C = 'SA11C',
  ///<summary>12. Transfer From Affiliated/Other Party Committee</summary>
  SA12 = 'SA12',
  ///<summary>13. Loans Received</summary>
  SA13 = 'SA13',
  ///<summary>14. Loan Repayment Recevied</summary>
  SA14 = 'SA14',
  ///<summary>15. Offset To Operating Expenditures (Refunds)</summary>
  SA15 = 'SA15',
  ///<summary>16. Refund Of Federal Contribution</summary>
  SA16 = 'SA16',
  ///<summary>17. Other Federal Receipts</summary>
  SA17 = 'SA17',
  ///<summary>Form 3L Schedule A</summary>
  SA3L = 'SA3L',
  ///<summary>21b. Other Federal Operating Expenditures</summary>
  SB21B = 'SB21B',
  ///<summary>22. Transfers To Affiliated/Other Party Committees</summary>
  SB22 = 'SB22',
  ///<summary>23. Contributions To Federal Candidates/Committees</summary>
  SB23 = 'SB23',
  ///<summary>26. Loand Repayments</summary>
  SB26 = 'SB26',
  ///<summary>27. Loans Made</summary>
  SB27 = 'SB27',
  ///<summary>28a. Individuals/Persons</summary>
  SB28A = 'SB28A',
  ///<summary>28b. Political Party Committees</summary>
  SB28B = 'SB28B',
  ///<summary>28c. Other Political Committees</summary>
  SB28C = 'SB28C',
  ///<summary>29. Other Disbursements</summary>
  SB29 = 'SB29',
  ///<summary>30b. Non-Allocable 100% Fed Election</summary>
  SB30B = 'SB30B',
  ///<summary>Form 3L Schedule B</summary>
  SB3L = 'SB3L',
  ///<summary>Schedule C - /9</summary>
  SC_9 = 'SC_9',
  ///<summary>Schedule C - /10</summary>
  SC_10 = 'SC_10',
  ///<summary>Schedule C1 - /9</summary>
  SC1_9 = 'SC1_9',
  ///<summary>Schedule C1 - /10</summary>
  SC1_10 = 'SC1_10',
  ///<summary>Schedule C2 - /9</summary>
  SC2_9 = 'SC2_9',
  ///<summary>Schedule C2 - /10</summary>
  SC2_10 = 'SC2_10',
  ///<summary>Schedule D - 9</summary>
  SD9 = 'SD9',
  ///<summary>Schedule D 10</summary>
  SD10 = 'SD10',
  ///<summary>Schedule E</summary>
  SE = 'SE',
  ///<summary>Schedule F</summary>
  SF = 'SF',
  ///<summary>Not Applicable</summary>
  NA = 'NA',
  ///<summary>Schedule Text</summary>
  TEXT = 'TEXT',
  ///<summary>For a Schedule Text when it does not reference any transaction on a new Form 3X</summary>
  F3XN = 'F3XN',
  ///<summary>For a Schedule Text when it does not reference any transaction on an amended Form 3X</summary>
  F3XA = 'F3XA',
}

export enum ImportStatus {
  ImportStarted = 'ImportStarted',
  ImportQueued = 'ImportQueued',
  SelectingWorksheets = 'SelectingWorksheets',
  ValidatingFile = 'ValidatingFile',
  ValidatedHasErrors = 'ValidatedHasErrors',
  Validated = 'Validated',
  Importing = 'Importing',
  ImportFailed = 'ImportFailed',
  ImportSucceeded = 'ImportSucceeded',
}

export enum ScheduleType {
  A = 'A',
  B = 'B',
  C = 'C',
  C1 = 'C1',
  C2 = 'C2',
  D = 'D',
  E = 'E',
  F = 'F',
  TEXT = 'TEXT',
  ///<summary>Not Set</summary>
  Unknown = 'Unknown',
}

export enum SortByColumn {
  TransactionId = 'TransactionId',
  Date = 'ContributionDate',
  Line = 'ScheduleLine',
  Amount = 'ContributionAmount',
  Aggregate = 'ContributionAggregateAmount',
  Employer = 'ContributorEmployer',
  Occupation = 'ContributorOccupation',
  OrgName = 'ContributorOrgName',
  Prefix = 'ContributorPrefix',
  FirstName = 'ContributorFirstName',
  MiddleName = 'ContributorMiddleName',
  LastName = 'ContributorLastName',
  Suffix = 'ContributorSuffix',
  Address = 'Address',
  City = 'City',
  State = 'State',
  Zip = 'Zip',
  DueDate = 'DueDate',
  DateIncurred = 'DateIncurred',
  LoanAmount = 'LoanAmount',
  InterestRate = 'InterestRate',
  Balance = 'Balance',
  BeginningBalance = 'BeginningBalance',
  BeginningAtClose = 'BeginningAtClose',
  DrawAmount = 'DrawAmount',
  PaymentAmount = 'PaymentAmount',
  Type = 'Type',
  Text = 'Text',
  ContributorAddressLine1 = 'ContributorAddressLine1',
  ContributorCity = 'ContributorCity',
  ContributorState = 'ContributorState',
  ContributorPostalCode = 'ContributorPostalCode',
  ContributionPurpose = 'ContributionPurpose',
  IsMemo = 'IsMemo',
  MemoText = 'MemoText',
  IsItemized = 'IsItemized',
  BackRefTransactionId = 'BackRefTransactionId',
  ContributorEntityType = 'ContributorEntityType',
}
