export enum StorageItemKey {
  FormCreationInfo = 'FormCreationInfo',
  SideBarCollapsed = 'SideBarCollapsed',
  CurrentUser = 'elf-currentUser',
}

export enum SideBarState {
  NotSet = 'NotSet',
  Collapsed = 'Collapsed',
  Expanded = 'Expanded',
}

// The C# FormBase property names that are used for sorting.
export enum FormBaseSortColumns {
  UniqueId = 'UniqueId',
  ReportPeriodStart = 'ReportPeriodStart',
  ReportPeriodEnd = 'ReportPeriodEnd',
  FecFilingId = 'FecFilingId',
  FormType = 'FormType',
  FormSubtype = 'FormSubtype',
  Status = 'Status',
  IsAmendment = 'IsAmendment',
  AmendmentNum = 'AmendmentNum',
}

export enum DateFormat {
  MDYYYY_Slash = 'M/D/YYYY',
  MMDDYYYY_Slash = 'MM/DD/YYYY',
  MMDDYY_Dash = 'MM-DD-YY',
  MMDD_Slash = 'MM/DD',
  YYYYMMDD_Dash = 'YYYY-MM-DD',
  dMMMMy_Dash_hmma = 'd MMMM, YYYY - h:mm a',
}

export enum DayJsInclusivity {
  ExclusiveStartExclusiveEnd = '()',
  ExclusiveStartInclusiveEnd = '(]',
  InclusiveStartExclusiveEnd = '[)',
  InclusiveStartInclusiveEnd = '[]',
}

export enum FecFileValidationState {
  Unknown = 'Unknown',
  InProgress = 'InProgress',
  Complete = 'Complete',
}

export enum SubmissionPageView {
  EnterSubmissionDetails = 'EnterSubmissionDetails',
  JobInProgress = 'JobInProgress',
  SubmissionResults = 'SubmissionResults',
}

export enum ReportTypeCategory {
  Quarterly = 'Quarterly',
  Monthly = 'Monthly',
  TwelveDay = 'TwelveDay',
  ThirtyDay = 'ThirtyDay',
  TerminationReport = 'TerminationReport',
}

export enum Form1MatTab {
  CommitteeInfo,
  CustodianDetails,
  AffiliatedCommittees,
  DesignatedAgents,
  BankAndOthers,
  JointFundraising,
}

export enum Form24MatTab {
  CommitteeInfo,
  ScheduleE,
}

export enum Form3LMatTab {
  Main,
  ScheduleA,
  ScheduleB,
}
export enum Form3XMatTab {
  Main,
  ScheduleA,
  ScheduleB,
  ScheduleC,
  ScheduleC1,
  ScheduleC2,
  ScheduleD,
  ScheduleE,
  ScheduleF,
  Text,
}

export enum FormTextBoxType {
  OrganizationName = 'OrganizationName',
  FirstName = 'FirstName',
  MiddleName = 'MiddleName',
  LastName = 'LastName',
  Prefix = 'Prefix',
  Suffix = 'Suffix',
}

export enum GridEditMode {
  Edit,
  ShowAll,
}

export enum ValidateTransactionIdResult {
  Ok = 'Ok',
  Duplicate = 'Duplicate',
  EqualsBackRef = 'EqualsBackRef',
}
