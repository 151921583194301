import { Injectable } from '@angular/core';
import { environmentUrls } from 'src/environments/environment-urls';
import * as localEnv from '../../../environments/environment';
import * as devEnv from '../../../environments/environment.dev';
import * as prodEnv from '../../../environments/environment.prod';
import * as testEnv from '../../../environments/environment.test';
import * as uatEnv from '../../../environments/environment.uat';
import { Environment } from '../models/api/enum';
import { EnvironmentSettings } from '../models/app/model';
import { BrowserService } from './browser.service';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  private readonly environment = this.determineEnvironment();

  public constructor(private browserService: BrowserService) {}

  public getEnvSettings(): EnvironmentSettings {
    let env = prodEnv.environment;
    switch (this.environment) {
      case Environment.Production:
        env = prodEnv.environment;
        break;
      case Environment.Local:
        env = localEnv.environment;
        break;
      case Environment.Development:
        env = devEnv.environment;
        break;
      case Environment.Test:
        env = testEnv.environment;
        break;
      case Environment.UAT:
        env = uatEnv.environment;
        break;
      default:
        env = { ...localEnv.environment, envName: Environment.Unknown };
    }
    return {
      appInsightsConnectionString: env.appInsightsConnectionString,
      auth: {
        authorityDomain: env.auth.authorityDomain,
        clientId: env.auth.clientId,
        domainName: env.auth.domainName,
        signInPolicy: env.auth.signInPolicy,
      },
      envName: env.envName,
      fecApiEndpoint: env.fecApiEndpoint,
      production: env.production,
    };
  }

  private determineEnvironment() {
    const hostname = this.browserService.getLocationHostname();
    let env = Environment.Unknown;
    if (environmentUrls.uiHostnamesProd.includes(hostname)) {
      env = Environment.Production;
    } else if (environmentUrls.uiHostnamesLocal.includes(hostname)) {
      env = Environment.Local;
    } else if (environmentUrls.uiHostnamesDev.includes(hostname)) {
      env = Environment.Development;
    } else if (environmentUrls.uiHostnamesTest.includes(hostname)) {
      env = Environment.Test;
    } else if (environmentUrls.uiHostnamesUat.includes(hostname)) {
      env = Environment.UAT;
    }

    return env;
  }
}
