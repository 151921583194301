import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { devTools } from '@ngneat/elf-devtools';
import rg4js from 'raygun4js';
import { AppModule } from './app/app.module';
import './app/shared/error-handling/raygun.setup';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(() => devTools())
  .catch((error: Error) => {
    /* eslint-disable no-console */
    console.error(error);
    rg4js('send', { error });
  });
