import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
} from '@azure/msal-angular';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DefaultModule } from './layouts/default/default.module';
import { EmptyModule } from './layouts/empty/empty.module';
import { GlobalErrorHandler } from './shared/error-handling/global-error-handler';
import { ClientInterceptor } from './shared/interceptors/client-interceptor';
import { SpinnerInterceptor } from './shared/interceptors/spinner-interceptor';
import { AuthConfigService } from './shared/services/auth-config.service';

@NgModule({
  declarations: [AppComponent],
  imports: [
    /** Angular modules */
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    MatButtonModule,
    MsalModule,
    MatToolbarModule,
    ToastrModule.forRoot(),
    /** Our modules */
    AppRoutingModule,
    DefaultModule,
    EmptyModule,
    NgxSpinnerModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SpinnerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ClientInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: AuthConfigService.getMsalConfig,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: AuthConfigService.getMsalGuardConfig,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: AuthConfigService.getMsalInterceptorConfig,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
