import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { AppInfo } from 'src/app/shared/models/api/model';
import { AppInfoService } from 'src/app/shared/services/api/app-info.service';
import { Environment } from '../../models/api/enum';
import { EnvironmentService } from '../../services/environment.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  public readonly appInfo$: Observable<AppInfo>;
  public readonly isUatOrProd: boolean;

  public constructor(
    private appInfoService: AppInfoService,
    private environmentService: EnvironmentService,
  ) {
    this.appInfo$ = this.appInfoService.getAppInfo();
    this.isUatOrProd = [Environment.UAT, Environment.Production].includes(environmentService.getEnvSettings().envName);
  }
}
