<mat-sidenav-container class='default-container'>
  <ng-container *ngIf="{sideBarState: sideBarState$ | async} as session">
    <mat-list role="list" class="sidebar" [ngStyle]="{'width':(session.sideBarState === SideBarState.Expanded) ? '15rem' : 'auto'}">
      <mat-list-item role="listitem" routerLinkActive="active-link">
        <a routerLink="/reports" class="flex h-full w-full items-center">
          <mat-icon matListItemIcon class="flex-none md-dark">file_copy</mat-icon>
          <div class="flex-auto ml-3 text-base" [hidden]="(session.sideBarState !== SideBarState.Expanded)">Reports</div>
        </a>
      </mat-list-item>
      <mat-list-item role="listitem" routerLinkActive="active-link">
        <a routerLink="/settings" class="flex h-full w-full items-center">
          <mat-icon matListItemIcon class="flex-none md-dark">settings</mat-icon>
          <div class="flex-auto ml-3 text-base" [hidden]="(session.sideBarState !== SideBarState.Expanded)">Settings</div>
        </a>
      </mat-list-item>
    </mat-list>
  </ng-container>
</mat-sidenav-container>