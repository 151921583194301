import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { NotificationService } from '../services/notification.service';
import { RaygunService } from './raygun.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  public constructor(
    private msalService: MsalService,
    private notificationService: NotificationService,
    private raygunService: RaygunService,
  ) {}

  /**
   * Show the user an error message and log the specified message or error.
   * @param error A string or Error object
   */
  public handleError(error: string | Error | object): void {
    if (error instanceof Error && error.message.includes('AADB2C90091')) {
      // We get here when the user cancels the sign-in/sign-up process. Don't show an error - just redirect the user to the login page.
      // More info: https://learn.microsoft.com/en-us/azure/active-directory-b2c/error-codes
      this.msalService.loginRedirect();
      return;
    }

    this.notificationService.showError(this.getNotificationMessage(error));
    this.raygunService.logError(error);
  }

  private getNotificationMessage(error: string | Error | object) {
    let message = 'Oops, there was an error';

    if (typeof error === 'string') {
      message = error;
    } else if (Object.prototype.hasOwnProperty.call(error, 'message')) {
      message = (error as { message: string }).message;
    }

    return message;
  }
}
