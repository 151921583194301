import { Environment } from 'src/app/shared/models/api/enum';
import { EnvironmentSettings } from 'src/app/shared/models/app/model';

export const environment: EnvironmentSettings = {
  appInsightsConnectionString:
    'InstrumentationKey=aacf0007-cf5c-48a9-aff6-ba53d34b10c4;IngestionEndpoint=https://westus2-2.in.applicationinsights.azure.com/;LiveEndpoint=https://westus2.livediagnostics.monitor.azure.com/',
  auth: {
    authorityDomain: 'aegispremier.b2clogin.com',
    clientId: 'dbe5ed3b-f8b3-4dce-be6b-9c4e9ffd01da', // Maps to Azure AD B2C application (client) ID
    domainName: 'aegispremier.onmicrosoft.com',
    signInPolicy: 'B2C_1A_SIGNUP_SIGNIN',
  },
  envName: Environment.Production,
  fecApiEndpoint: 'https://fecapi.aegispremier.com',
  production: true,
};
