import { Component, OnInit } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { SideBarState, StorageItemKey } from 'src/app/shared/models/app/enum';
import { AuthenticationService } from '../../services/authentication.service';
import { BrowserSessionStorageService } from '../../services/browser-session-storage.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public sideBarState$: Observable<SideBarState> = of(SideBarState.NotSet);
  public SideBarState = SideBarState;

  public constructor(
    private authenticationService: AuthenticationService,
    private browserSessionStorageService: BrowserSessionStorageService,
  ) {}

  public ngOnInit(): void {
    this.sideBarState$ = this.browserSessionStorageService
      .select<boolean>(StorageItemKey.SideBarCollapsed)
      .pipe(
        map((value) => (value === null ? SideBarState.NotSet : value ? SideBarState.Collapsed : SideBarState.Expanded)),
      );
  }

  public logout() {
    this.authenticationService.logout();
  }

  public toggleSession(sideBarState: SideBarState) {
    const sessionValue = !(sideBarState === SideBarState.Collapsed);
    this.browserSessionStorageService.set<boolean>(StorageItemKey.SideBarCollapsed, sessionValue);
  }
}
